import axios, { AxiosResponse } from 'axios'
import { apiUrl } from '@/env';
import {
  IUserProfile,
  ILandingOrder,
  ILandingOrderResponse,
  IOrder,
  IInvoiceResponse,
  IPaymentResponse,
  ISignUp,
  IProductSentencesResponse,
  INewOrder,
  IDevice,
  IBalances,
  IDexRate,
} from './interfaces'
import {
  WalletConfirmType,
  WithdrawalLimitsType,
  WithdrawalWalletAddressType,
  WithdrawalWalletType,
  WithdrawConfirmType,
} from '@/interfaces/wallet.type'

function authHeaders(token: string) {
  if (token) {
    return {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
  } else {
    return {};
  }
}

  function checkAPIResult(result: AxiosResponse | any, from: string | undefined) {
  // console.debug('%c checkAPIResult(%c%s%c)', 'color:aqua;', 'color:gold;', from, 'color:aqua;', result)
  console.debug(
    '%c checkApiResult(%c%s%c) STATUS:(%s) %c RESULT:',
    'color:aqua;',
    'color:gold;font-size:14px;',
    from,
    'color:aqua;',
    result.status,
    'color:lime;',
    result,
    // result.data
  )

  return result

  // if (result?.data?.status === "error") {
  //   throw new ApiError(
  //     result.data.error,
  //     result.data.code,
  //   )
  // }
  //
  // if (result?.data?.status === "ok") {
  //   return result.data.data
  // }
  //
  // throw new ApiError(
  //   "empty result or wrong data: "
  //   + (result?.data?.error ? result.data.error : result?.data),
  //   API_ERROR_CODE.UNKNOWN
  // )
}

export const api = {
  async getDexRate(id: number) {
    return axios.get<IDexRate>(`${apiUrl}/api/v1/devices/dexrate/${id}`);
  },
  async submitLogIn(data: {username: string, password: string, code: string}) {
    return axios.post<{token: string}>(`${apiUrl}/api/v1/accounts/login/`, data);
  },
  async submitSignUp(data: ISignUp) {
    return axios.post<{token: string}>(`${apiUrl}/api/v1/accounts/register/`, data);
  },
  async submitResetPassword(data: {email: string}) {
    return axios.post(`${apiUrl}/api/v1/accounts/password/reset/`, data);
  },
  async submitLandingOrder(data: ILandingOrder) {
    return axios.post<ILandingOrderResponse>(`${apiUrl}/api/v1/products/create_order_and_register_user/`, data);
  },
  async submitNewOrder(token: string, data: INewOrder) {
    return axios.post<IOrder>(`${apiUrl}/api/v1/products/create_order/`, data, authHeaders(token));
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async getOrdersList(token: string) {
    return axios.get<IOrder[]>(`${apiUrl}/api/v1/products/orders/`, authHeaders(token));
  },
  async getDevicesList(token: string) {
    return axios.get<IDevice[]>(`${apiUrl}/api/v1/devices/`, authHeaders(token));
  },
  async validateSerial(token: string, data: { device_id: string, order: number }) {
    return axios.post<any>(`${apiUrl}/api/v1/devices/validate/`, data, authHeaders(token));
  },
  async registerDevice(token: string, data: { device_id: string, order: number }) {
    return axios.post<any>(`${apiUrl}/api/v1/devices/register/`, data, authHeaders(token));
  },
  async getOrCreateInvoice(token: string, data: {order: number, standart: string, token?: string}) {
    return axios.post<IInvoiceResponse>(`${apiUrl}/api/v1/payments/get_or_create_invoice/`, data, authHeaders(token));
  },
  async getPaymentStatus(token: string, data: {address: string, invoice: number}) {
    return axios.post<IPaymentResponse>(`${apiUrl}/api/v1/payments/get_payment_status/`, data, authHeaders(token));
  },
  async getProductSentencesSet(token: string, data: {product_sentences_set: string, promocode?: string}) {
    return axios.post<IProductSentencesResponse>(`${apiUrl}/api/v1/products/products/product_sentences_set/`, data, authHeaders(token));
  },
  async submitUpdateOrder(
    token: string, data: {
      order: number,
      // delivery_address?: string,
      starlink_kit?: boolean,
      promocode?: string,
      shipped?: boolean,
    },
  ) {
    return axios.post<IProductSentencesResponse>(`${apiUrl}/api/v1/products/update_order/`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async logInByToken(authToken: string, language: string) {
    const params = new URLSearchParams();
    params.append('token', authToken);
    params.append('language', language);

    return axios.post(`${apiUrl}/api/v1/accounts/token-auth-bot/`, params);
  },
  async getBalances(token: string) {
    return axios.post<IBalances>(`${apiUrl}/api/v1/payments/balances/`, {}, authHeaders(token))
  },
  async getPaymentsHistory(token: string, page = 1) {
    const getParam = page > 1 ? `?page=${page}` : '';
    return axios.get<any>(`${apiUrl}/api/v1/payments/history/${getParam}`, authHeaders(token));
  },
  async getStakingTerms(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/tokenomics/staking_terms/`, authHeaders(token));
  },
  async getStakingRegisters(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/tokenomics/staking_registers/`, authHeaders(token));
  },
  async createStaking(token: string, data: {
    staking_terms: number,
    quantity: number,
  }) {
    console.debug('%c api.createStaking(%s)', 'color:#b0b', JSON.stringify(data, null, 4))
    return axios.post<any>(`${apiUrl}/api/v1/tokenomics/staking_create/`, data, authHeaders(token));
  },

  async getStakingStatus(token: string, id: number | string) {
    return axios.get<any>(`${apiUrl}/api/v1/tokenomics/staking_detail/${id}/`, authHeaders(token));
  },



  async updateStaking(token: string, data: {
    staking_register: number,
    staking_terms: number,
  }) {
    console.debug('%c api.createStaking(%s)', 'color:#b0b', JSON.stringify(data, null, 4))
    return axios.post<any>(`${apiUrl}/api/v1/tokenomics/staking_update/`, data, authHeaders(token));
  },



  async getNodesData() {
    return axios.get<any>(`${apiUrl}/api/v1/profixone/devices/statistics/`);
  },

  async getWithdrawalLimits(token: string): Promise<AxiosResponse<WithdrawalLimitsType>> {
    return axios.get<any>(`${apiUrl}/api/v1/withdraw/withdraw_limits/`, authHeaders(token));
  },

  async getWithdrawalWallet(token: string): Promise<AxiosResponse<WithdrawalWalletType>> {
    return axios.get<any>(`${apiUrl}/api/v1/withdraw/withdraw_wallet/`, authHeaders(token));
  },

  async setWithdrawalWallet(token: string, data: WithdrawalWalletAddressType) {
    return axios.post<any>(`${apiUrl}/api/v1/withdraw/withdraw_wallet/`, data, authHeaders(token));
  },

  async walletConfirm(data: WalletConfirmType) {
    return axios.post<any>(`${apiUrl}/api/v1/withdraw/confirm_withdraw_wallet/`, data);
  },

  async walletRemove(data: WalletConfirmType) {
    return axios.post<any>(`${apiUrl}/api/v1/withdraw/unconfirm_withdraw_wallet/`, data);
  },

  async withdrawRequest(token: string, data: {
    amount: number,
  }) {
    return axios.post(`${apiUrl}/api/v1/withdraw/request/`, data, authHeaders(token));
  },

  async withdrawConfirm(data: WithdrawConfirmType) {
    return axios.post<any>(`${apiUrl}/api/v1/withdraw/confirm_withdraw_request/`, data);
  },

  async getAccount(token) {
    return axios.get<any>(`${apiUrl}/api/v1/accounts/me/`, authHeaders(token));
  },

  async getBlocksProgress(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/tokenomics/active_block/`, authHeaders(token));
  },

  async getReplenishInfo(token: string) {
    return axios.post<any>(`${apiUrl}/api/v1/payments/replenish/`, {}, authHeaders(token));
  },

  async activateValidator(token: string, data:any) {
    return axios.post<any>(`${apiUrl}/api/v1/validators/activate/`, data, authHeaders(token));
  },

  async createValidatorInvite(token: string, data:any) {
    return axios.post<any>(`${apiUrl}/api/v1/validators/create_invoice/`, data, authHeaders(token));
  },

  async getDeliveryCountries(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/products/delivery_countries/`, authHeaders(token));
  },

  async getDPNData(token: string) {
    return axios.get<any>(`${apiUrl}/api/v1/promotions/dpn/token/get/`, authHeaders(token));
  },

  async validatePromocode(token: string, data: { code: string }) {
    return axios.post<any>(`${apiUrl}/api/v1/promotions/validate-code/`, data, authHeaders(token));
  },

  async blockchainPresaleRegister( data: any) {
    return axios.post<any>(`https://api.dexid.dexnet.one/api/v1/accounts/blockchain/presail/register/`, data);
  }
};
